.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 40px;
  background-color: rgb(235, 235, 231);
}

@media (min-width: 768px) {
  .bottom-section {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .top-section {
    display: flex;
    justify-content: space-between;
    margin: 40px 60px;
    align-items: center;
  }
}
.image {
  width: 100%;
  height: 500px;
  display: flex;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1200px) {
  .image {
    width: 855px;
    height: 600px;
  }
}
.text-image {
  position: absolute;
  top: 16px;
  font-weight: bold;
  color: #fff;
  font-size: 40px;
  left: 0;
}
@media (min-width: 1200px) {
  .text-image {
    top: 550px;
    font-size: 28px;
    color: rgb(235, 235, 231);
  }
}
@media (min-width: 1400px) {
  .text-image {
    left: 1%;
  }
}
@media (min-width: 1600px) {
  .text-image {
    left: 4%;
  }
}
.big-words {
  position: absolute;
  /* bottom: 100px; */
  left: 0;
  font-size: 20px;
  font-weight: bold;
  top: 230px;
}
@media (min-width: 768px) {
  .big-words {
    top: 140px;
    right: 40px;
  }
}
@media (min-width: 1024px) {
  .big-words {
    top: 220px;
  }
}
@media (min-width: 1200px) {
  .big-words {
    top: 0;
    position: relative;
    /* padding-top: 120px; */
    font-size: 50px;
  }
}
.right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1200px) {
  .right-side {
    margin-left: 24px;
  }
}
@media (min-width: 1200px) {
  .right-side {
  }
}
.under-contract {
  color: #ff5252;
  position: absolute;
  background-color: #fff;
  font-size: 40px;
  padding: 4px;
  font-weight: bold;
  top: 420px;
  width: 300px;
  left: 10%;
}
@media (min-width: 1440px) {
  .under-contract {
    top: 140px;
    left: 20px;
    transform: rotate(-20deg);
  }
}
.about {
  margin-top: 8px;
}
@media (min-width: 1200px) {
  .about {
    margin-top: 0;
  }
}
.title {
  color: rgb(31, 27, 27);
  font-weight: 'bold';
  margin-top: 20px;
  font-size: 40px;
}
@media (min-width: 1200px) {
  .title {
    color: #3e3429;
    margin-top: 60px;
    font-size: 66px;
  }
}
.amount {
  color: rgb(210, 233, 190);
  font-weight: 'bold';
  padding-left: 8px;
}
@media (min-width: 768px) {
  .amount {
    color: #77b28a;
  }
}
.outer-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 16px;
  justify-content: center;
}
@media (min-width: 768px) {
  .outer-cards {
    margin: 24px 50px 40px 50px;
  }
}
.cards {
  border: 1px solid #e5e5ea;
  padding: 8px;
  border-radius: 5px;
  width: 140px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  color: #3e3429;
  background-color: #fff;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
}
@media (min-width: 768px) {
  .cards {
    height: 270px;
    width: 270px;
    padding: 24px;
    margin-right: 16px;
  }
}
.person-name {
  font-size: 24px;
  font-weight: bold;
  color: #3e3429;
}
@media (min-width: 768px) {
  .person-name {
    font-size: 30px;
  }
}
.donate-button {
  background-color: #77b28a;
  width: 138px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  font-size: 12px;
}
@media (min-width: 768px) {
  .donate-button {
    height: 40px;
    margin-top: 50px;
    width: 200px;
    font-size: 16px;
  }
}
.goal-text {
  font-size: 20px;
  color: #3e3429;
  padding-top: 16px;
}
@media (min-width: 768px) {
  .goal-text {
    font-size: 32px;
  }
}
.section-title {
  color: #3e3429;
  font-size: 48px;
  position: absolute;
  top: -25px;
  font-family: Snell Roundhand, cursive;
  font-weight: bold;
  line-height: 1;
}
@media (min-width: 768px) {
  .section-title {
    line-height: 2;
    top: -50px;
  }
}
.info-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .info-section {
    justify-content: flex-end;
    align-items: inherit;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 56px;
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
